function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useState, useLayoutEffect, useEffect, useRef, useMemo, useContext, useDebugValue } from 'react';
import { Context } from '../context';
import memoize from '../utils/memoize';
import shallowEqual from '../utils/shallow-equal';

var EMPTY_SELECTOR = function EMPTY_SELECTOR() {
  return undefined;
};

var DEFAULT_SELECTOR = function DEFAULT_SELECTOR(state) {
  return state;
}; // As we want to subscribe ASAP and useEffect happens on next tick, but
// React currently throws a warning when using useLayoutEffect on the server


var useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

var useUnmount = function useUnmount(fn) {
  return useIsomorphicLayoutEffect(function () {
    return fn;
  }, []);
}; // We memoize both the input and the output
// so if input args are shallow equal we do not recompute the selector
// and also when we do, check if output is shallow equal to prevent children update


var createMemoizedSelector = function createMemoizedSelector(selector) {
  var memoSelector = memoize(selector);
  var lastResult;
  return function (currentState, hookArg) {
    var result = memoSelector(currentState, hookArg);

    if (!shallowEqual(result, lastResult)) {
      lastResult = result;
    }

    return lastResult;
  };
};

var handleStoreSubscription = function handleStoreSubscription(subscriptionRef) {
  var onUpdateRef = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var storeState = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

  if (subscriptionRef.current) {
    subscriptionRef.current.remove();
    subscriptionRef.current = null;
  }

  if (storeState && onUpdateRef) {
    // we call the current ref fn so state is fresh
    var onUpdate = function onUpdate() {
      return onUpdateRef.current.apply(onUpdateRef, arguments);
    };

    subscriptionRef.current = {
      storeState: storeState,
      remove: storeState.subscribe(onUpdate)
    };
  }
};

export function createHook(Store) {
  var _ref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      selector = _ref.selector;

  return function useSweetState(propsArg) {
    var _useContext = useContext(Context),
        getStore = _useContext.getStore;

    var _getStore = getStore(Store),
        storeState = _getStore.storeState,
        actions = _getStore.actions; // If selector is not null, create a ref to the memoized version of it
    // Otherwise always return same value, as we ignore state


    var stateSelector = selector ? // eslint-disable-next-line react-hooks/rules-of-hooks
    useMemo(function () {
      return createMemoizedSelector(selector);
    }, []) : selector === null ? EMPTY_SELECTOR : DEFAULT_SELECTOR; // At every render we get fresh state and using recent propsArg
    // we calculate the current value, to be used immediately

    var currentState = stateSelector(storeState.getState(), propsArg);
    useDebugValue(currentState);

    var _useState = useState(0),
        _useState2 = _slicedToArray(_useState, 2),
        triggerUpdate = _useState2[1];

    var subscriptionRef = useRef(null); // We store update function into a ref and re-create on each render
    // so when called gets fresh currentState and props

    var onUpdateRef = useRef();

    onUpdateRef.current = function () {
      var updStoreState = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : storeState.getState();
      var forceUpdate = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      // if unsubscribed / unmounted do not set state
      if (!subscriptionRef.current) return;
      var nextState = stateSelector(updStoreState, propsArg);

      if (nextState !== currentState || forceUpdate) {
        // we need a different value on each update
        // otherwise React might optimise the state update and discard it!
        triggerUpdate(function (n) {
          return n + 0.1;
        });
      }
    }; // On first render or on scope change we subscribe
    // The inline subscription allows us to ensure:
    // - the order of updates is always top to bottom
    // - we get store updates since component inception
    // - we change subscription on scope change asap


    if (!subscriptionRef.current || subscriptionRef.current.storeState !== storeState) {
      handleStoreSubscription(subscriptionRef, onUpdateRef, storeState);
    } // On component unmount we unsubscribe to storeState updates


    useUnmount(function () {
      return handleStoreSubscription(subscriptionRef);
    });
    return [currentState, actions];
  };
}