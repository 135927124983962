import defaults from '../defaults';

var namedMutator = function namedMutator(storeState, actionName) {
  return function () {
    storeState.mutator.actionName = actionName;
    return storeState.mutator.apply(storeState, arguments);
  };
};

export var bindAction = function bindAction(storeState, actionFn, actionKey) {
  var getContainerProps = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {};
  var boundActions = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

  // Setting mutator name so we can log action name for better debuggability
  var dispatch = function dispatch(thunkFn) {
    var actionName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "".concat(actionKey, ".dispatch");
    return thunkFn({
      setState: defaults.devtools ? namedMutator(storeState, actionName) : storeState.mutator,
      getState: storeState.getState,
      actions: boundActions,
      dispatch: dispatch
    }, getContainerProps());
  };

  return function () {
    return dispatch(actionFn.apply(void 0, arguments), actionKey);
  };
};
export var bindActions = function bindActions(actions, storeState) {
  var getContainerProps = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : function () {
    return {};
  };
  var boundActions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
  return Object.keys(actions).reduce(function (acc, k) {
    acc[k] = bindAction(storeState, actions[k], k, getContainerProps, boundActions || acc);
    return acc;
  }, {});
};