import defaults from '../defaults';

var connectDevTools = function connectDevTools(storeState) {
  var devTools = window.__REDUX_DEVTOOLS_EXTENSION__.connect({
    name: "Store ".concat(storeState.key),
    serialize: true
  });

  devTools.init(storeState.getState());
  devTools.subscribe(function (message) {
    if (message.type === 'DISPATCH') {
      switch (message.payload.type) {
        case 'RESET':
          storeState.resetState();
          devTools.init(storeState.getState());
          return;

        case 'COMMIT':
          devTools.init(storeState.getState());
          return;

        case 'ROLLBACK':
          storeState.setState(JSON.parse(message.state));
          devTools.init(storeState.getState());
          return;

        case 'JUMP_TO_STATE':
        case 'JUMP_TO_ACTION':
          storeState.setState(JSON.parse(message.state));
          return;
      }
    } else if (message.type === 'ACTION') {
      var action = JSON.parse(message.payload);
      storeState.setState(action.payload);
    }
  });
  return devTools;
};

var withDevtools = function withDevtools(createStoreState) {
  return function () {
    var storeState = createStoreState.apply(void 0, arguments);

    if (defaults.devtools && window && window.__REDUX_DEVTOOLS_EXTENSION__) {
      var origMutator = storeState.mutator;
      var devTools;

      var devtoolMutator = function devtoolMutator(arg) {
        var result = origMutator(arg);

        try {
          if (!devTools) {
            devTools = connectDevTools(storeState);
          }

          devTools.send({
            type: storeState.mutator.actionName,
            payload: arg
          }, storeState.getState(), {}, storeState.key);
        } catch (err) {
          /* ignore devtools errors */
        }

        return result;
      };

      storeState.mutator = devtoolMutator;
    }

    return storeState;
  };
};

export default withDevtools;