import React, { useContext } from 'react';

var MutexContext = React.createContext([]);

var createMutex = function (context) {
    return /** @class */ (function () {
        function Mutex(id) {
            var _this = this;
            this.run = function (resolveCallback, rejectCallback) {
                if (!_this.isLocked()) {
                    resolveCallback();
                }
                else {
                    if (rejectCallback && typeof rejectCallback === 'function') {
                        rejectCallback();
                    }
                }
            };
            this.lock = function () {
                if (!_this.isLocked()) {
                    context.push(_this.id);
                }
            };
            this.unlock = function () {
                var index = context.indexOf(_this.id);
                if (index > -1) {
                    context.splice(index, 1);
                }
            };
            this.isLocked = function () {
                return context.indexOf(_this.id) > -1;
            };
            this.id = id;
        }
        return Mutex;
    }());
};

var useMutex = function () {
    var mutexContext = useContext(MutexContext);
    var MutexRunner = createMutex(mutexContext);
    return MutexRunner;
};

export { useMutex };
