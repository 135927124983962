function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { bindActions } from './bind-actions';
import createStoreState from './create-state';
export var GLOBAL_SCOPE = '__global__';
export var StoreRegistry = function StoreRegistry() {
  var _this = this;

  var defaultScope = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : GLOBAL_SCOPE;

  _classCallCheck(this, StoreRegistry);

  _defineProperty(this, "stores", new Map());

  _defineProperty(this, "initStore", function (Store, key) {
    var initialState = Store.initialState,
        actions = Store.actions;
    var storeState = createStoreState(key, initialState);
    var boundActions = bindActions(actions, storeState);
    var store = {
      storeState: storeState,
      actions: boundActions
    };

    _this.stores.set(key, store);

    return store;
  });

  _defineProperty(this, "getStore", function (Store) {
    var scopeId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _this.defaultScope;

    var key = _this.generateKey(Store, scopeId);

    return _this.stores.get(key) || _this.initStore(Store, key);
  });

  _defineProperty(this, "deleteStore", function (Store) {
    var scopeId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : _this.defaultScope;

    var key = _this.generateKey(Store, scopeId);

    _this.stores.delete(key);
  });

  _defineProperty(this, "generateKey", function (Store, scopeId) {
    return "".concat(Store.key.join('__'), "@").concat(scopeId);
  });

  this.defaultScope = defaultScope;
};
export var defaultRegistry = new StoreRegistry();